const url = "http://localhost/lms/";

// ------ DEVELOPMENT URL ------ 
// const devUrl = "https://lmsv2-dev.portalwiz.in/";
// const storageUrl = "https://lmsv2-dev.portalwiz.in/lms/storage/app/";
// const baseUrl = "https://lmsv2-dev.portalwiz.in/lms/public/api/";

// ------ Production URL ------ 
// const devUrl = "https://portal.guruscool.com/";
// const storageUrl = "https://portal.guruscool.com/lms/storage/app/";
// const baseUrl = "https://portal.guruscool.com/lms/public/api/";

// ------ Production Clone GCP URL ------ 
const devUrl = "https://portaltest.guruscool.com/";
const storageUrl = "https://portaltest.guruscool.com/lms/storage/app/";
const baseUrl = "https://portaltest.guruscool.com/lms/public/api/";


export default baseUrl;
export { baseUrl, url, storageUrl, devUrl };